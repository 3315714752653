@import "~antd/dist/antd.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

.App {
  text-align: center;
}

body {
  font-family: "Poppins";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.dashboardModal .ant-modal-content {
  border-radius: 10px;
  padding: 30px;
  background: url("./assets/svg/dashboardModel.svg") no-repeat center center /
    cover;
}

.ant-layout {
  /* background: linear-gradient(
    117.83deg,
    #f3fbff 10.85%,
    #b3dcf9 96.06%
  ) !important; */
}

.ant-menu {
  background: #fff !important;
}

.ant-menu > .ant-menu-item {
  height: 72px;
  margin: 0;
  line-height: initial;
  padding: 0;
  display: flex;
  align-items: center;
  /* text-align: center; */
  border-left: 3px solid transparent !important;
  border-right: 3px solid transparent !important;
}

.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  background-color: #1271a6 !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  padding-left: 0%;
  padding-right: 0%;
}

.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a > span {
  pointer-events: none;
  color: #fff !important;
}

.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a > img {
  filter: brightness(0) invert(1) !important;
}

.ant-menu > .ant-menu-item-open svg path,
.ant-menu > .ant-menu-submenu-open svg path,
.ant-menu > .ant-menu-item-selected svg path,
.ant-menu > .ant-menu-submenu-selected svg path {
  fill: #1890ff;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: inline-block;
}
.ant-checkbox {
  width: 27px;
}
.ant-checkbox-checked > .ant-checkbox-inner {
  background-color: #1371a6 !important;
  border: 1px solid #1371a6 !important;
}
.ant-checkbox-checked::after {
  border: none !important;
}

.fixed {
  align-items: flex-start;
}
.fixed .ant-checkbox {
  top: 0;
}

.decision_table_checkbox .ant-checkbox-inner {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: white;
}

.select_all {
  transform: translate(0, 5px) !important;
}

.decision_table_option_checkbox {
  position: relative;
}

.decision_table_option_checkbox .ant-checkbox-inner {
  position: absolute;
  top: -3px;
  left: 6px;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: white;
  border: 2px solid #cdcdcd;
}

.option_checkbox div {
  color: rgb(255, 255, 255);
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  padding: 0;
}
input[type="checkbox"] {
  position: relative;
  cursor: pointer;
  transform: translateY(-2px);
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 19px;
  height: 19px;
  top: 0;
  left: 0;
  border-radius: 3px;
  background-color: #ecf3f7;
  border: 1px solid rgb(193, 191, 191) !important;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #1271a6;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 3px;
  left: 7px;
}
input:disabled {
  cursor: not-allowed;
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding: 0% !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0% !important;
}
/* .ant-dropdown-menu {
  background-color: #1271a6 !important;
  width: 220px;
  height: auto;
} */
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  opacity: 0 !important;
}

.ant-select-selector {
  /* border-color: #5D83AB !important; */
  border: none !important;
  background-color: transparent !important;
}

.ant-select-open {
  /* border-radius: 30px !important; */
  /* background-color: #5D83AB !important; */
}

.ant-select-open > .ant-select-arrow {
  color: #fff !important;
  text-decoration-line: underline;
}

.ant-select-arrow {
  color: #fff !important;
}

.darkSelector > .ant-select-selector > span {
  color: #1271a6 !important;
}

.darkSelector > .ant-select-open > .ant-select-arrow {
  color: #1271a6 !important;
  text-decoration-line: underline;
}

.darkSelector > .ant-select-selection-placeholder {
  color: #1271a6 !important;
  text-decoration-line: underline;
}

.darkSelector > .ant-select-arrow {
  color: #1271a6 !important;
}

.whiteSelector {
  margin-top: 10px;
  margin-bottom: -10px;
}

.whiteSelector > .ant-select-selector > span {
  color: #fff !important;
  font-size: 12px;
}

.whiteSelector > .ant-select-open > .ant-select-arrow {
  color: #fff !important;
  text-decoration-line: underline;
}

.whiteSelector > .ant-select-selection-placeholder {
  color: #fff !important;
  text-decoration-line: underline;
}

.whiteSelector > .ant-select-arrow {
  color: #fff !important;
}

.actionItemCollapse > div > div {
  width: 100%;
}

.actionItemCollapse .ant-collapse-content {
  position: relative;
  z-index: 9;
}

.ant-table-thead .ant-table-cell {
  background-color: #2d8cc1 !important;
  color: #fff;
  text-transform: capitalize !important;
  text-decoration-line: none !important;
}

.deleteModal > .ant-modal-content > .ant-modal-body {
  padding: 0px;
}

.modalStyle > .ant-modal-content > .ant-modal-header > div {
  font-weight: bold !important;
}

.ant-table {
  border-radius: 5px !important;
}

.editable-row {
  font-weight: bold;
}

.ant-table-content {
  border-radius: 5px !important;
}

.firstCell {
  border-left: 5px solid #f4771b !important;
  height: 100%;
}

.orangeText {
  color: #f4771b;
}

.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #feab3d;
}

.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  color: #1689ca !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #feab3d !important;
}

.ant-tabs-ink-bar {
  background: #feab3d !important;
}

.ant-table-tbody .ant-table-cell {
  background-color: #fffef6;
}

.actionItemHeader .ant-table-cell {
  background-color: #fff;
  border: 1px solid #eff7fe;
  padding: 0 12px !important;
  height: 46px;
}

.cursor-pointer {
  cursor: pointer;
}

.categoryTable .ant-table-cell {
  background-color: #f9f9f9;
  border: 1px solid #eff7fe;
}

.categoryTable .ant-table-thead .ant-table-cell {
  background-color: #1271a6;
  color: #fff;
  text-transform: uppercase !important;
  text-decoration-line: none !important;
}

.categoryTable .ant-table-content {
  border-radius: 5px !important;
}

.actionItemHeader > div > div > div {
  margin-left: -8px !important;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  width: 100% !important;
  display: flex;
  align-items: center;
}

.row-dragging td:nth-child(1) {
  width: 60px !important;
}

.row-dragging td:nth-child(2) {
  max-width: 400px;
  width: 100%;
}

.row-dragging td:nth-child(3) {
  width: 100%;
}

.row-dragging td:nth-child(4) {
  max-width: 100px;
  width: 100%;
}

.row-dragging td {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  padding: auto;
}

.row-dragging .drag-visible {
  visibility: visible;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #112538 #f1f1f1;
}

/* Handle */
*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #112538;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #112538;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-progress {
  display: block;
}

.ant-progress-outer {
  display: block;
}

.ant-progress-inner {
  display: block;
}

.ant-tabs-nav::before {
  border-bottom-color: #c0d3e7 !important;
}
/* .ant-modal-body {
  padding: 0rem !important;
}
.ant-modal-content {
  background-color: transparent !important;
} */
.ant-popover-inner-content {
  width: 100%;
  /* padding: 0rem; */
}
.ant-popover-arrow {
  display: none;
}

.popoverWrapper {
  display: flex;
  flex-direction: column;
  width: 180px;
  border-radius: 6px;
  background: #1271a6;
  box-shadow: 0px 0px 8px rgba(16, 24, 40, 0.25);
  z-index: 1000;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0rem;
}
.popoverDiv {
  width: 158px;
  height: 39px;
  padding: 11px 12px;
  font-family: "Inter", serif;
  font-style: normal;
  border-radius: 5px;
  font-weight: 400;
  background: #1271a6;
  font-size: 14px;
  line-height: 20px;
  color: white;
  cursor: pointer;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popoverDiv:hover {
  background: #418db8;
}
.ant-switch-checked {
  background-color: #418db8;
}

.popoverOptionsWrapper {
  display: flex;
  flex-direction: column;
  width: 195px;
  border-radius: 6px;
  background: #1271a6;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0rem;
}
.popoverOptionsDiv {
  width: 195px;
  height: 39px;
  padding: 11px 12px;
  font-family: "Inter", serif;
  font-style: normal;
  border-radius: 5px;
  font-weight: 500;
  background: #1271a6;
  font-size: 12px;
  line-height: 16px;
  color: white;
  cursor: pointer;
  width: 90%;
  display: flex;
  align-items: center;
}
.popoverOptionsDiv:hover {
  background: #418db8;
}

/* .ant-popover-inner {
  background-color: transparent !important;
} */

.popoverCriteriaWrapper {
  display: flex;
  flex-direction: column;
  width: 390px;
  background: #1271a6;
  z-index: 1000;
  padding: 0.5rem 1rem;
}
.popoverCriteriaDiv {
  width: 390px;
  height: 39px;
  padding: 11px 12px;
  font-family: "Inter", serif;
  font-style: normal;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: white;
  cursor: pointer;
  width: 90%;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}
.activeButton {
  background: #116595;
}
.popoverCriteriaDiv:hover {
  background: #418db8;
}
.sortContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}
.rangeContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.label {
  display: flex;
  align-items: center;
  font-family: "Inter";
  margin-bottom: 0rem;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: 1px;
  text-transform: uppercase;

  /* Generic/White */

  color: #ffffff;
}

.dropdownSelector {
  background: #116595;
  border-color: white;
  border-radius: 6px;
  color: white;
}
.deactivateCriteriaContainer {
  display: flex;
  justify-content: flex-end;
}
.switchButton {
  margin-left: 1rem;
}
/* .ant-modal-body {
  padding: 0px !important;
} */
.dropdownManageCriteria {
  z-index: 1500;
}

.weight_slider {
  margin: 0;
  padding: 0;
}

.weight_slider .ant-slider-rail {
  background-color: #ffffff !important;
  height: 8px;
  border-radius: 100px;
}

.weight_slider .ant-slider-track {
  height: 8px;
  background-color: #1271a6 !important;
  border-radius: 100px;
}

.weight_slider .ant-slider-handle {
  background: #ffffff !important;
  box-shadow: 0px 2px 6px -1px rgba(16, 24, 40, 0.2),
    0px 1px 4px -2px rgba(16, 24, 40, 0.2);
  border: none;
  width: 20px;
  height: 20px;
  margin-top: -7px;
}

.checkbox {
  margin-top: -18px;
}
.checkbox .ant-checkbox-checked > .ant-checkbox-inner {
  background-color: #00c5db !important;
  border: 1px solid #00c5db !important;
}
.checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #00c5db;
}

.popoverDivPrint:hover {
  background: transparent !important;
}
.popoverDivPrint {
  font-weight: 400;
}

textarea {
  scrollbar-width: thin;
  scrollbar-color: #bdc3c7 transparent;
}

textarea:hover {
  scrollbar-color: darken(#bdc3c7, 20%) transparent;
}

textarea::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

textarea::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

textarea::-webkit-scrollbar-thumb {
  background: #bdc3c7;
  border-radius: 50px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background: darken(#bdc3c7, 20%);
}

textarea::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

/* .custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("./assets/svg/select_down.svg") no-repeat right center;
  padding-right: 30px;
} */

/* Style the down arrow icon */
.custom-select::-ms-expand {
  display: none;
  padding-right: 30px;
}

.dashboardCollapse .ant-collapse-item {
  min-height: 33px !important;
}
.dashboardCollapse .ant-collapse-header {
  margin-bottom: 10px;
}

.ant-table-header {
  max-height: 41px !important;
}
@media screen and (max-width: 1100px) {
  .ant-table-header {
    max-height: 59px !important;
  }
}

@media screen and (max-width: 900px) {
  .ant-table-header {
    max-height: 80px !important;
  }
  .dashboardModal .ant-modal-content {
    background: #fff;
  }
}

.exclamation_error {
  position: absolute;
  top: -1px;
  right: 20px;
}

.error-tooltip .ant-tooltip-content .ant-tooltip-inner {
  color: #404040;
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter";
  background-color: #fff;
  border-radius: 4px !important;
  border-top: 3px solid #1271a6;
  padding: 12px !important;
}

.error-tooltip
  .ant-tooltip-content
  .ant-tooltip-arrow
  .ant-tooltip-arrow-content {
  --antd-arrow-background-color: #fff !important;
}

.ant-table-body {
  overflow-y: auto !important;
}

.toolsUpdateModel {
  max-height: 280px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* padding-bottom: 15px; */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.toolsUpdateModel::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.disablePadding .ant-modal-body {
  padding: 0px !important;
  /* Handle */
}

.model-header {
  padding: 20px 12px;
}

.model-header h3 {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #171717;
  font-size: 18px;
}

.model-header svg {
  cursor: pointer;
  position: relative;
  top: -4px;
}

.metricModel .ant-modal-content {
  border-radius: 4px;
  max-height: 650px;
}

.metricModel .ant-modal-body {
  padding: 0px !important;
}

.projectDetail-select .ant-select-selector {
  box-shadow: none !important;
}

.projectDetail-select .ant-select-arrow {
  color: #a3a3a3 !important;
}

.projectDetail-datepicker {
  padding: 10px !important;
  border-radius: 4px !important;
}

.measurement-popover-overlay {
  padding-top: 3px !important;
}

.measurement-popover {
  width: 280px;
  border-radius: 4px !important;
}

.measurement-popover-items {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #f5f5f5;
}

.measurement-popover-item {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.measurement-popover-item label {
  color: #737373;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 6px;
}

.measurement-popover-item input {
  width: 78px;
  height: 38px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 10px 12px;
  background-color: #fff;
  text-align: center;
}

.measurement-popover-item input[type="number"]::-webkit-outer-spin-button,
.measurement-popover-item input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.measurement-popover-item input[type="number"] {
  -moz-appearance: textfield;
}

.measurement-popover-item input[type="number"] {
  padding: 8px;
  outline: none;
}

.measurement-popover-item input[type="number"]:focus {
  border: 1px solid #71aaca;
  box-shadow: 0px 0px 0px 2px rgba(208, 227, 237, 1);
}

.measurement-popover-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 10px;
}

.measurement-popover-actions button {
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 12px;
  font-family: "Inter";
  border: none;
  outline: none;
}

.measurement-popover-actions button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.measurement-popover-actions button:nth-child(1) {
  background-color: #ecf3f6;
  color: #1271a6;
}

.measurement-popover-actions button:nth-child(2) {
  background-color: #1271a6;
  color: #fff;
}

.antd-assignee .ant-select-selection-item {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 auto;
  background: #b9c9da;
  text-transform: uppercase;
  color: #fff !important;
  font-size: 12px;
  flex: initial;
  cursor: pointer;
  transition: 0.3s ease;
  overflow: hidden;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.antd-assignee .ant-select-selector {
  display: flex;
  align-items: center;
  justify-content: center;
}

.antd-suffixIcon .ant-picker-clear {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-dropdown-select {
  height: 300px;

  width: 150px !important;
}
.custom-dropdown-select .ant-select-item-option-content {
  width: 150px !important;

   /* Ensures content takes full width of Select dropdown */
}

.custom-dropdown-select .sub-option-dropdown {
  min-width: 150px !important;

   /* Matches the width of the Select component */
}



.measurement-popover-item .ant-dropdown-menu {
  width: 150px; /* Align dropdown width to Select component */
}

.custom-dropdown .rc-virtual-list-holder {
  overflow-y: auto !important;
}


.CustomizePopover .ant-popover-inner-content {
  padding: 0 !important;
}

.custom-dropdown-select {
  height: 300px;
  width: 150px !important;
}
.custom-dropdown-select .ant-select-item-option-content {
  width: 150px !important;
}

.custom-dropdown-select .sub-option-dropdown {
  min-width: 150px !important;
}

.measurement-popover-item .ant-dropdown-menu {
  width: 150px; /* Align dropdown width to Select component */
}

.antd-dropDown-borderRadius .ant-dropdown-menu {
  border-radius: 4px !important;
}

.ai_tooltip .ant-tooltip-inner {
  padding: 4px 8px !important;
  min-height: max-content !important;
  font-size: 10px !important;
}

.ai_popover .ant-popover-inner {
  border-radius: 13px !important;
  width: 333px !important;
}

.ai_popover .ant-popover-arrow {
  display: block !important;
}

.assistant-drawer .ant-drawer-content {
  height: 99% !important;
  border-radius: 8px !important;
}

.assistant-drawer .ant-drawer-content-wrapper {
  top: 8px !important;
  right: 10px !important;
}

.assistant-drawer .ant-drawer-body {
  padding: 14px 12px !important;
}

.weight-select .rc-virtual-list-holder {
  overflow-y: auto !important;
}

.ant-picker-input:hover .ant-picker-suffix {
  visibility: hidden;
  opacity: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.category-container {
  position: relative;
}

.threshold-select .ant-select-selector {
  border: 1px solid #e5e5e5 !important;
  background-color: transparent !important;
  width: 100px !important;
}

.threshold-select .ant-select-arrow {
  color: #737373 !important;
}

.weight-table {
  overflow-x: auto;
  border: 1px solid #e5e5e5 !important;
  border-radius: 2px;
  margin-bottom: 16px;
}

.weight-table .ant-table-thead .ant-table-cell {
  background-color: #ecf3f6 !important;
  color: #171717 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.weight-table .ant-table-thead .ant-table-cell::before {
  display: none !important;
}

.weight-header-actions {
  display: flex;
  align-items: center;
  gap: 30px;
}

.weight-header-actions button {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  color: #ff9900;
  display: flex;
  align-items: center;
  gap: 6px;
  visibility: hidden;
  opacity: 0;
}

.weight-header-actions button:disabled {
  cursor: not-allowed;
  opacity: 0.6 !important;
}

.weight-table
  .ant-table-thead
  .ant-table-cell:nth-child(1):hover
  .weight-header-actions
  button {
  visibility: visible;
  opacity: 1;
}
.weight-table .ant-table-tbody .ant-table-cell {
  background-color: #fff !important;
}

.weight-table .ant-table-tbody .ant-table-cell:nth-child(1) {
  min-width: 335px !important;
}

.weight-table .ant-table-tbody .ant-table-cell:nth-child(2) {
  min-width: 670px !important;
}

.weight-table .ant-table-tbody .ant-table-cell:nth-child(3) {
  min-width: 115px !important;
}
